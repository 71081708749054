<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        class="position-relative"
        :items="locations"
        :fields="fields"
        responsive
        show-empty
        empty-text="No locations found"
        :busy="loading"
      >
        <template v-slot:cell(address)="data">
          {{ data.item.location_street }} {{ data.item.location_city }}, {{ data.item.location_state }} {{ data.item.location_zip_code }}
        </template>

        <template v-slot:cell(actions)="data">
          <b-button
            size="sm"
            variant="primary"
            :to="{
              name: 'location',
              params: data.item
            }"
          >
            Details
          </b-button>

          <!-- button -->
          <b-button
            size="sm"
            variant="outline-primary"
            class="ml-1"
            @click="downloadItemInventoryReport(data.item)"
          >
            Inventory Report
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import locationStoreService from '@/services/locationStoreService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import csvDownload from 'json-to-csv-export'

export default {
  components: {
    BCard,
    BTable,
    BButton,
  },
  setup() {
    // Store
    const LOCATIONS_MODULE_KEY = 'app-location'

    if (!store.hasModule(LOCATIONS_MODULE_KEY)) store.registerModule(LOCATIONS_MODULE_KEY, locationStoreService)

    onUnmounted(() => {
      if (store.hasModule(LOCATIONS_MODULE_KEY)) store.unregisterModule(LOCATIONS_MODULE_KEY)
    })

    // Variables
    const locations = ref([])
    const loading = ref(false)
    const toast = useToast()
    const fields = [
      {
        key: 'location_name',
        label: 'Name',
      },
      {
        key: 'address',
      },
      {
        key: 'location_phone_number',
        label: 'Phone',
      },
      {
        key: 'actions',
      },
    ]

    // Functions
    const fetch = () => {
      loading.value = true
      store.dispatch('app-location/fetch')
        .then(response => {
          locations.value = response.data
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong fetching order locations please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loading.value = false
        })
    }

    const downloadItemInventoryReport = location => {
      store.dispatch('app-location/itemInventoryReport', {
        locationId: location.location_id,
        enforceThreshold: false,
      })
        .then(response => {
          const dataToConvert = {
            data: response.data,
            filename: `${location.location_name}_inventory_report.csv`,
            delimiter: ',',
          }

          csvDownload(dataToConvert)

          toast({
            component: ToastificationContent,
            props: {
              title: `${location.location_name} item inventory report successfully downloaded`,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong generating the inventory report for this location',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    fetch()

    return {
      locations,
      loading,
      fields,

      downloadItemInventoryReport,
    }
  },
}
</script>
